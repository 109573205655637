import * as React from 'react'
import PropTypes from 'prop-types'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const LargeCard = ({ padding, className, children }) => {
    const classes = [
        'c-large-card',
        ...(padding ? [`c-large-card--${padding}`] : []),
        ...(className ? [className] : [])
    ]

    const concatenatedClasses = joinClasses(classes)

    return <div className={concatenatedClasses}>{children}</div>
}

LargeCard.propTypes = {
    /**
     * Amend default padding
     */
    padding: PropTypes.oneOf(['no-horizontal']),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired
}

LargeCard.defaultProps = {
    children: '<p>Card content goes here</p>'
}

export default LargeCard
